<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <h6 class="brandhead">EDUCATION HUB</h6>
      <!-- <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 556 134"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
      /> -->
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="navItems" />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from "./_nav";

export default {
  name: "TheSidebar",
  nav: [],
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
    navItems() {
      const sideMenuNavs = this.$store.state.s_tabMenuNavs;
      // if (sideMenuNavs.length > 0) {
      //   this.$router.push({
      //     name: sideMenuNavs[0].name,
      //     to: sideMenuNavs[0].to,
      //   });
      // }
      return [
        {
          _name: "CSidebarNav",
          _children: [...sideMenuNavs],
        },
      ];
    },
  },
};
</script>
<style scoped>
.brandhead {
  font-size: 16px;
  text-shadow: 1px 1px #ccc;
  margin-bottom: 0px;
}
</style>
