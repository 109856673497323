<template>
  <CHeader fixed with-subheader light>
    <!-- Session Expired Modal -->
    <CModal
      title="Session Expired"
      color="danger"
      :closeOnBackdrop="false"
      :show.sync="sessionEnabled"
      @update:show="closeSessionModal"
    >
      Oops! your session is expired, Please login to continue.
    </CModal>
    <!-- / Session Expired Modal -->
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <CIcon name="logo" height="48" alt="Logo" />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3" v-if="isCallCenter == 'callcenter'">
        <CHeaderNavLink @click.native="configureTabMenu('callcenter')">
          Call center
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3" v-if="isCallCenter == 'admin'">
        <CHeaderNavLink @click.native="configureTabMenu('admin')" exact>
          Admin
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3" v-if="isCallCenter == 'admin'">
        <CHeaderNavLink @click.native="configureTabMenu('insights')">
          Insights
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-envelope-open" />
        </CHeaderNavLink>
      </CHeaderNavItem>
      <TheHeaderDropdownAccnt />
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import Cookies from "js-cookie";
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import store from "../store/index";
export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
  },
  data() {
    return {
      sessionEnabled: false,
    };
  },
  computed: {
    isCallCenter() {
      return this.$store.getters.g_role;
    },
    isSessionEnabled() {
      return this.$store.getters.g_sessionStatus == "error" ? true : false;
    },
  },
  created() {
    if (!this.$store.getters.g_role && Cookies.get("edu_role")) {
      this.$store.dispatch("a_SetRole", Cookies.get("edu_role"));
    }
    this.configureTabMenu(Cookies.get("edu_lasttab"));

    this.$store.dispatch("a_session");
  },
  methods: {
    configureTabMenu(tab) {
      Cookies.set("edu_lasttab", tab);
      store.dispatch("a_configureTabMenu", tab).then((item) => {
        const tabMenu = store.getters.g_tabMenuNavs;
        if (this.$route.name != tabMenu[0].name) {
          this.$router.replace({
            name: tabMenu[0].name,
          });
        }
      });
    },

    closeSessionModal(status, evt, accept) {
      this.$store.dispatch("a_LoggedOut").then(() => {
        this.$router.replace({
          name: "Login",
        });
      });
      // window.location.href = "/";
    },
  },
  mounted() {},
  watch: {
    isSessionEnabled(newV, oldV) {
      this.sessionEnabled = newV;
    },
  },
};
</script>
